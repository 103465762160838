html {
  font-family: Arial, Helvetica, sans-serif;
  background-color: #414141;
}

a {
  color: #db9cff;
}

#Heading {
  text-align: center;
  color: white;
}

#Warning {
  color: white;
}

#Info {
  border-bottom: 1px solid black;
  padding-bottom: 55px;
  color: white;
  text-align: center;
  font-size: 35px;
}

#Extra {
  color: white;
  font-size: 35px;
  border-bottom: 1px solid black;
  padding-bottom: 55px;
  text-align: center;
  font-size: 35px;
}